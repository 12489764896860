<template>
<footer>
    <div class="container d-lg-flex py-4" style="align-items: flex-end;">

      <div class="me-lg-auto text-center text-lg-start" >
        <div class="copyright">
          &copy; Copyright <strong><span>Markuci Inc</span></strong>. All Rights Reserved
        </div>
      </div>
      <div class="social-links text-center text-lg-right pt-3 pt-lg-0">
        <a href="#" class="facebook"><i class="lni lni-facebook-filled"></i></a>
        <a href="#" class="twitter"><i class="lni lni-twitter-original"></i></a>
        <a href="#" class="linkedin"><i class="lni lni-linkedin-original"></i></a>
      </div>
    </div>
    </footer>
    <a href="#" class="scroll-top"><i class="lni lni-chevron-up"></i></a>
</template>
<style>
.social-links {
    padding-right: 30px;
}
@media (max-width: 998px) and (min-width: 0px)  {
.social-links {
    padding-right: 0px !important;
}
}
.scroll-top:hover {
    box-shadow: 0 1rem 3rem rgba(35,38,45,.15)!important;
    transform: translate3d(0,-5px,0);
    background-color: #ed1d8d;
}
.scroll-top {
    width: 45px;
    height: 45px;
    line-height: 45px;
    background: #72287a;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #fff!important;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 9;
    cursor: pointer;
    transition: all .3s ease-out 0s;
    border-radius: 5px;
}
footer {
    box-shadow: 0px 20px 50px 0px rgb(0 0 0 / 60%);
    background: #fff;
    padding: 0;
    width: 100%;
}
.credits {
  padding-top: 5px;
  font-size: 13px;
  color: #313030;
}
 .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #72287a;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
.social-links a:hover {
  background: #ed1d8d;
  color: #fff;
  text-decoration: none;
}
</style>