<template>

     <section id="about" class="about aboutdesktop">
      <div class="container">
        <div class="row">
          <div class="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start">
          
          </div>
          <div class="col-xl-7 aboutus">
            <div class="aboutcontent">
              <h2>ABOUT US</h2>
              <p style="padding-bottom:10px;font-weight:600">What We Do</p>
              <div class="row">
                <div class="col-md-12 icon-box" >
                  <p>Markuci delivers digital transformation and technology services from ideation to execution enabling global companies move up the Value Chain. A strong blend of expertise and execution with passion, Markuci delivers quality experiences while driving greater efficiency and modernizing business operations. We work with people and organizations who have ambitious missions by setting up smart teams who love challenges and think disruptively to help our clients succeed. </p>
                    <p> We offer the most cost effective, secure, and scalable models to service our clients across various verticals such as banking, hi-tech, education, and entertainment to name a few. We engineer meaningful technology solutions to help businesses and societies move from Good to Great. Our innovations have allowed us to provide an SLA of anywhere from 0~24hours and the ability to scale over 1,000+ apps a day.  </p>
                    <p>Markuci, a division of Serco Global Services Pvt Ltd was formed in 2002 by a team of highly qualified and experienced professionals from the Enterprise software world. The management team collectively having over 100 years of experience, came together with the goal of leveraging their international industry experience to build a global, comprehensive services organization with the highest quality of services. We are a software company and a group of passionate, purpose-led individuals who believe in developing true partnerships.</p>
                </div>
              </div>
            </div><!-- End .content-->
          </div>
        </div>

      </div>
    </section><!-- End About Section -->
</template>
<style>
@media (max-width: 991px) and (min-width: 0px)  {
    .image.col-xl-5{
    display: none !important;
}
.aboutus{
    padding: 0px 30px !important;
}
}
.aboutus{
    padding: 20px 60px;
}
.about .image {
  background: url("../assets/images/About-us.jpg") center center no-repeat;
  background-size: cover;
  min-height: 400px;
}
@media (max-width: 667px) {
  .about .image img {
    max-width: 100%;
  }
}
.about .container {
  position: relative;
}
.about {
  padding: 100px 30px 80px 30px;
  background: linear-gradient( 180deg, #f2f6fa 0%, #fff 100%);
}
.aboutcontent h2 {
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    font-family: "Poppins", sans-serif;
    color: #72287a;
}
.aboutcontent p {
    font-size: 16px;
    text-align: justify;
    font-family: "Open Sans", sans-serif;
    color: #444444;
}
</style>