<template>
    <section id="solution" class="services section-bg">
      <div class="container">
         <div class="section-title">
          <h2>OUR SOLUTION</h2>
          <p style="font-weight:600;">What We Do With Love</p>
        </div>

        <div class="row" >
          <div class="col-lg-6 col-md-6 col-12 d-flex align-items-stretch"  v-for="(obj, key) in solutionJSON" :key="key" >
            <div class="card">
              <div class="card-img">
                <Image :fpath=" obj.path "/>
              </div>
              <div class="card-body">
                <h5 class="card-title">{{ obj.header }}</h5>
                <p class="card-text">{{ obj.caption }}</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section><!-- End Values Section -->


</template>

<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    solutionJSON: [
      {
        path: 'Enterprise.jpg',
        header: 'ENTERPRISE',
        caption: 'Markuci expertly manages applications for some of the world’s most successful organizations.'
      },
      {
        path: 'ANALYTICS.jpg',
        header: 'ANALYTICS',
        caption: 'Big data unlocks hidden opportunities and insights. Markuci turns technology into business outcomes.'
      },
       {
         path: 'APPS.jpg',
        header: 'APPS',
        caption: 'We work with you to understand your business and device customized solutions maximize application availability, agility and predictability.'
      },
      {
        path: 'STRATEGY.jpg',
        header: 'STRATEGY',
        caption: 'Markuci professionals define, design and execute strategies that drive your business growth, reduce costs and create new revenue streams.'
      },
      {
        path: 'DIGITAL.jpg',
        header: 'DIGITAL',
        caption: 'Markuci works with you to transform your business by leveraging digital technologies.'
      },
      {
        path: 'MANAGEMENT.jpg',
        header: 'MANAGEMENT',
        caption: 'Markuci’s has increased the performance of our clients by about 80% by optimising their resources and utilising them judiciously using technological innovations.'
      }
    ]

  })
    
}
</script>
<style>
p{
  font-family:Open Sans, sans-serif;
}
 .card-body p {
    font-size: 16px;
    text-align: justify;
    font-family: "Open Sans", sans-serif;
    color: #444444;
}
.services {
  padding: 110px 30px 40px 30px;
}
.services .card {
  border: 0;
  padding: 0 30px;
  margin-bottom: 60px;
  position: relative;
  background: transparent;
}
.services .card-img {
  width: calc(100% + 60px);
  margin-left: -30px;
  overflow: hidden;
  z-index: 9;
  border-radius: 0;
}
.services .card-img img {
  max-width: 100%;
  transition: all 0.3s ease-in-out;
}
.services .card-body {
  z-index: 10;
  background: #fff;
  border-top: 4px solid #fff;
  padding: 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  margin-top: -60px;
  transition: 0.3s;
}
.section-title h2 {
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    font-family: "Poppins", sans-serif;
    color: #72287a;
}
.services .card-title {
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
.services .card-title {
  color: #72287a;
  transition: 0.3s;
}
.services .card-text {
  color: #5e5e5e;
}
.services .card:hover img {
  transform: scale(1.1);
}
.services .card:hover .card-body {
  border-color: #ed1d8d;
}
.services .card:hover .card-body .card-title {
  color: #ed1d8d;
}
.section-title {
    text-align: center;
    padding-bottom: 30px;
}
.section-bg {
    background: linear-gradient(
180deg, #f2f6fa 0%, #fff 100%);
}
.section-title h2::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
}
.section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #ed1d8d;
    bottom: 0;
    left: calc(50% - 20px);
}
@media (max-width: 991px) and (min-width: 0px)  {
.card-body p {
    text-align: center !important;
}
}
</style>