<template>
    <section id="contact" class="contact">
      <div class="container">    
        <div class="row ">
        <div class="section-title text-center">
          <h2 >GET IN TOUCH</h2>
          <p  style="font-weight:600;">Feel Free To Contact Us</p>
        </div>
      <div class="col-lg-6">
        <div class="row ">
            <div class="col-xl-12 col-lg-4 align-items-stretch" style="padding-bottom: 20px;">
                <div class="info-box">
              <i class="lni lni-map-marker"></i>
              <h3>US Head Quarters</h3>
              <p>4595, Chabot Drive, Suite 200, Pleasanton, California, - 94588.<br></p>
            </div>
          </div>

          <div class="col-xl-12 col-lg-4 align-items-stretch" style="padding-bottom: 20px;">
                <div class="info-box">
              <i class="lni lni-map-marker"></i>
              <h3>Indian Head Quarters</h3>
              <p>No. 30, Srinivasa Nagar 1st street, OFF OMR Road, Kandhanchavadi, Chennai - 600096.</p>
            </div>
          </div>

          <div class="col-xl-6 col-md-6 align-items-stretch" style="padding-bottom: 20px;">
            <div class="info-box">
              <i class="lni lni-envelope"></i>
              <h3>Email Us</h3>
              <p>contact@markuci.com<br>support@markuci.com</p>
            </div>
          </div>
          <div class="col-xl-6 col-md-6 align-items-stretch" style="padding-bottom: 20px;">
            <div class="info-box">
              <i class="lni lni-phone"></i>
              <h3>Call Us</h3>
              <p>+1 925 302 6900<br>+1 323 870 3030</p>
            </div>
          </div>
        </div>
      </div>
      
      <div class="col-lg-6" style="padding-top:10px">
        <div class="row">
          <div class="col-xl-12 col-lg-12" id="Markuci">
            <form @submit.prevent="sendEmail" role="form" class="php-email-form">
              <div class="row">
                <div class="col-md-6 form-group">
                  <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required>
                </div>
                <div class="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required>
                </div>
              </div>
              <div class="form-group mt-3">
                <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required>
              </div>
              <div class="form-group mt-3">
                <textarea class="form-control" name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <div class="text-center"><button type="submit"  value="Send">Send Message</button></div>
            </form>

              <div v-if="testButClicked" class="alert alert-success" role="alert">Submitted Successfully</div>
          </div>

        </div>
      </div>
      </div>
      </div>
    </section>
</template>
<script>
import emailjs from 'emailjs-com'

  export default {
    el: "#element",
      data() {
         return {
            testButClicked: false
          }
      },
       methods: {
           sendEmail(e) {
             this.testButClicked = true;
           emailjs.sendForm('service_Markuci', 'template_Contact', e.target, 'user_44TwNC7w6C35UtEBPAfaN')
           .then((result) => { 
                // alert("Successful Message");
                window.location.reload()
                console.log('SUCCESS!', result.status, result.text);
                }, (error) => {
                    console.log('FAILED...', error);
                 });
                }
              }, 

         watch:{
              testButClicked(val){
                 if (val===true){
                    setTimeout(()=>this.testButClicked=false,8000);
                  }
                }
            }
    }
</script>
<style>
.contact{
  padding: 110px 30px 50px 30px;
      background: linear-gradient(
180deg, #f2f6fa 0%, #fff 100%);
}
.card-body {
  background: rgba(255, 255, 255, 0.9);
  padding: 15px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 60%);
  transition: 0.3s;
  transition: ease-in-out 0.4s;
  border-radius: 5px;
}
.contact .info-box {
  color: #313030;
  box-shadow: 0 0 30px rgba(214, 215, 216, 60%);
  padding: 20px;
}
.contact .info-box i {
  font-size: 32px;
  color: #ed1d8d;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #72287a;
  float: left;
}
.contact .info-box h3 {
  font-size: 15px;
    color: #656262;
    font-weight: 700;
    margin: 10px 0 10px 70px;
}
.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin: 0 0 0 70px;
}
.contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
}
.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed1d8d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}
.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}
.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}
.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}
.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}
.contact .php-email-form input, .contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}
.contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
  border-color: #ed1d8d;
}
.contact .php-email-form input {
  padding: 10px 15px;
}
.contact .php-email-form textarea {
  padding: 12px 15px;
}
.contact .php-email-form button[type=submit] {
  color: #333;
    padding: 13px 43px;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 500;
    margin: 20px 0 0 0px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    -ms-border-radius: 5px;
    border: 1px solid #333;
    background: none;
}
.contact .php-email-form button[type=submit]:hover {
  background: #ffffff;
    border: 1px solid #eb196f;
    color: #eb196f;
    transition: .5s all;
    -webkit-transition: .5s all;
    -o-transition: .5s all;
    -ms-transition: .5s all;
    -moz-transition: .5s all;
}
@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>