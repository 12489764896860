<template>
    <section id="industries" class="features section-bg">
      <div class="container">

        <div class="section-title">
          <h2>INDUSTRIES</h2>
          <p style="font-weight:600;">Industries we serve</p>
        </div>

        <div class="row content">
          <div class="col-md-5 text-center" >
             <img src="../assets/images/Banking.svg" style="width: 85%;" alt="">
          </div>
          <div class="col-md-7 servicescontent" >
            <h3>Banking</h3>
            <p style="padding-top:10px">Markuci transforms leading banks and financial institutions by delivering innovative services and scalable strategic solutions. We support mission-critical infrastructure and banking software applications that helps you to serve your customers better.</p>
          </div>
        </div>

        <div class="row content">
          <div class="col-md-5 order-1 order-md-2 text-center" >
             <img src="../assets/images/Consumer-Device.svg" style="width: 80%;" alt="">
          </div>
          <div class="col-md-7 order-2 order-md-1 servicescontent servicescontent" >
           <h3>Consumer Device & Electronics</h3>
            <p style="padding-top:10px">Today’s consumers expect companies to go beyond developing products and deliver solutions that suit their lifestyles—including new form factors, apps and cloud services that enhance the user experience.</p>
          </div>
        </div>

        <div class="row content">
          <div class="col-md-5 text-center" >
             <img src="../assets/images/Manufacturing.svg" style="width: 100%;" alt="">
            
          </div>
          <div class="col-md-7 servicescontent" >
            <h3>Manufacturing</h3>
            <p style="padding-top:10px">Markuci helps automobile and discrete manufacturers ignite and realize their strategic goals. By aligning production processes with customer demands, we facilitate collaboration with suppliers for better returns.</p>
          </div>
        </div>

        <div class="row content">
          <div class="col-md-5 order-1 order-md-2 text-center" >
             <img src="../assets/images/Insurance.svg" style="width: 70%;" alt="">
          </div>
          <div class="col-md-7 order-2 order-md-1 servicescontent servicescontent" >
           <h3>Insurance</h3>
            <p style="padding-top:10px">Insurers all over the world use Markuci to improve their competitive edge. We offer services specifically designed for Property & Casualty and Life Insurance submarkets.</p>
          </div>
        </div>

        <div class="row content">
          <div class="col-md-5 text-center" >
             <img src="../assets/images/Capital-Markets.svg"  style="width: 100%;" alt="">
          </div>
          <div class="col-md-7 servicescontent" >
            <h3>Capital Markets</h3>
            <p style="padding-top:10px">Global Investment banks, brokerage firms and exchanges rely on Markuci services and solutions to distribute capital more efficiently and respond to changing market conditions.</p>
          </div>
        </div>

        <div class="row content">
          <div class="col-md-5 order-1 order-md-2 text-center" >
             <img src="../assets/images/Packaged-Goods.svg" style="width: 85%;" alt="">
          </div>
          <div class="col-md-7 order-2 order-md-1 servicescontent servicescontent" >
           <h3>Consumer Packaged Goods</h3>
            <p style="padding-top:10px">Markuci works with global CPG enterprises to reshape their IT strategies for success. Narrow profit margins. And steep brand competition demands for technology enabled solutions.</p>
          </div>
        </div>
        
        <div class="row content">
          <div class="col-md-5 text-center" >
             <img src="../assets/images/Retail.svg" style="width: 80%;" alt="">
          </div>
          <div class="col-md-7 servicescontent" >
            <h3>Retail</h3>
            <p style="padding-top:10px">Markuci helps retailers transform their technology to gain a 360-degree view of consumers and serve them across the entire purchase process. It provides cost effective and robust services to retailers in order to help them serve their customers better.</p>
          </div>
        </div>

        <div class="row content">
          <div class="col-md-5 order-1 order-md-2 text-center" >
             <img src="../assets/images/Automobile.svg" style="width: 80%;" alt="">
          </div>
          <div class="col-md-7 order-2 order-md-1 servicescontent servicescontent" >
           <h3>Automobile</h3>
            <p style="padding-top:10px">Accelerate productivity—and revenues—through innovative solutions that improve the visibility of orders, reduce unnecessary inventory, boost sales force automation and enable better reporting.</p>
          </div>
        </div>

      </div>
    </section>
</template>
<style >
@media (max-width: 998px) and  (min-width: 0px) {
.servicescontent{
    padding-top: 20px !important;
}
.content {
    padding-top: 20px !important;
}
.features .content h3 {
    font-size: 22px !important;
}
}
.features {
    padding: 110px 30px 80px 30px;
}
.features .content + .content {
  margin-top: 10px;
}
.features .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #213b52;
}
.features .content p:last-child {
  margin-bottom: 0;
}
.servicescontent{
    padding-top: 100px;
}
</style>