<template>
    <section id="why-us" class="why-us">
      <div class="container-fluid">

        <div class="row">

          <div class="col-lg-5 align-items-stretch video-box">
          </div>

          <div class="col-lg-7 datasection d-flex flex-column justify-content-center align-items-stretch" data-aos="fade-left">

            <div class="content">
              <h3 style="margin-bottom:30px">Why Choose Us</h3>
              </div>

            <div class="accordion-list">
              <ul>
                <li>
                  <a data-bs-toggle="collapse" class="collapse" data-bs-target="#accordion-list-1"><span>01</span>We Earn Trust - 100% Customer References <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                  <div id="accordion-list-1" class="collapse show" data-bs-parent=".accordion-list">
                    <p>Every one of our customers is a reference for Markuci and the work we do. We don’t cherry pick customers for references or testimonials and we invite you to ask any of our customers about the work we’ve done. We know that being a trusted partner is more than just a promise. We earn your trust – every day.</p>
                  </div>
                </li>

                <li>
                  <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" class="collapsed"><span>02</span>Innovations in Technology and Methodology <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                  <div id="accordion-list-2" class="collapse" data-bs-parent=".accordion-list">
                    <p>Our innovative approach is rooted in all aspects of our existence and we strive to foster innovation, top‑down and bottom‑up, throughout our organization and on all client engagements. Our experts identify opportunities to apply technology solutions that provide you with a customizable framework and an integrated strategy within your development lifecycle. This results in a complete solution that’s tailored to meet your needs.</p>
                  </div>
                </li>
                <li>
                  <a data-bs-toggle="collapse" class="collapsed" data-bs-target="#accordion-list-3"><span>03</span>Value with a Measurable ROI<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                  <div id="accordion-list-3" class="collapse" data-bs-parent=".accordion-list">
                    <p>Markuci was founded with a vision to provide value-added solutions that significantly improve the quality of software by leveraging technology. The key to value can be pricing, and we price our services so that businesses of any size can get maximum benefit from their investment. Not only do we guarantee complete satisfaction, we also guarantee a measurable ROI (return on investment). If we’re not able to significantly help you and deliver a measurable ROI, you owe us nothing.</p>
                  </div>
                </li>

                <li>
                  <a data-bs-toggle="collapse" data-bs-target="#accordion-list-4" class="collapsed"><span>04</span>Our Talent - Markucians<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                  <div id="accordion-list-4" class="collapse" data-bs-parent=".accordion-list">
                    <p>Markuci is known for the high caliber of our talented testing professionals. Our customers have repeatedly told us that they have worked with other organizations and that our professionals have a higher level of expertise than they were able to obtain anywhere else.</p>
                  </div>
                </li>
                <li>
                  <a data-bs-toggle="collapse" class="collapsed" data-bs-target="#accordion-list-5"><span>05</span>Flexible and Agile<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                  <div id="accordion-list-5" class="collapse" data-bs-parent=".accordion-list">
                    <p>Our customers are unique. They conduct their businesses in a world of constantly and rapidly changing objectives and priorities. We think and act quickly – and can stop on a dime or make a "U" turn around really quick, if the business so demands. Our approach to all of our projects require us to be flexible and agile for our customers so that we can support them for whatever their needs are.</p>
                  </div>
                </li>

                <li>
                  <a data-bs-toggle="collapse" data-bs-target="#accordion-list-6" class="collapsed"><span>06</span>Challenging<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                  <div id="accordion-list-6" class="collapse" data-bs-parent=".accordion-list">
                    <p>As a dynamic, young and innovative organization, we're not afraid to think outside of the box or go out of our way to meet IT challenges. We are always eager for challenging projects as well as watchful for exceptional talent to strengthen our team of creative professionals.</p>
                  </div>
                </li>

              </ul>
            </div>

          </div>

        </div>

      </div>
    </section>
</template>

<style>
.video-box {
  background-image: url("../assets/why-choose-us.jpg");
}
.why-us {
  background: #f1f8ff;
  padding: 0;
}
a {
    text-decoration: none;
}
.why-us .content {
  padding: 60px 100px 0 100px;
}
.datasection {
    padding: 0px 30px;
}
.why-us .content h3 {
  font-weight: 400;
  font-size: 34px;
}

.why-us .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}

.why-us .content p {
  font-size: 15px;
  color: #848484;
}

.why-us .video-box {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 400px;
  position: relative;
}

.why-us .accordion-list {
  padding: 0 100px 60px 100px;
}

.why-us .accordion-list ul {
  padding: 0;
  list-style: none;
}

.why-us .accordion-list li + li {
  margin-top: 15px;
}

.why-us .accordion-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

.why-us .accordion-list a {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-right: 30px;
  outline: none;
  cursor: pointer;
  color: #ed1d8d;
}

.why-us .accordion-list span {
  color: #72287a;
  font-weight: 600;
  font-size: 18px;
  padding-right: 10px;
}

.why-us .accordion-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.why-us .accordion-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.why-us .accordion-list .icon-show {
  display: none;
}

.why-us .accordion-list a.collapsed {
  color: #343a40;
}

.why-us .accordion-list a.collapsed:hover {
  color: #ed1d8d;
}

.why-us .accordion-list a.collapsed .icon-show {
  display: inline-block;
}

.why-us .accordion-list a.collapsed .icon-close {
  display: none;
}


@media (max-width: 1024px) {
  .why-us .content, .why-us .accordion-list {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .why-us .content {
    padding-top: 30px;
  }
  .why-us .accordion-list {
    padding-bottom: 30px;
  }
}

</style>