<template>
<div class="maincontent">
   <div class="container-fluid" >
    <div class="row">
            <div class="col-12 text-center" style="padding-top:30px">
                      <div class="section-title">
                        <h2>OUR CLIENTS</h2>
                      </div>
              <Carousel :autoplay="3000" :transition="1000" :items-to-show="5" :itemsToScroll="3" :wrap-around="true" class="ClientCarousel desktop">
                <Slide v-for="slide in slider" :key="slide.id" class="clientslide">
                  <div class="carousel__item slider">
                    <div class="carousel-container">
                      <Image :fpath="slide.path"  class="clientimg"/>
                       </div>
                  </div>
                </Slide>
                <template #addons>
                <Navigation class="clientnextprev" />
                  <Pagination />
                </template>
              </Carousel>
              <Carousel :autoplay="3000" :transition="1000" :items-to-show="3" :itemsToScroll="3" :wrap-around="true" class="ClientCarousel mobileclient">
                <Slide v-for="slide in slider" :key="slide.id" class="clientslide">
                  <div class="carousel__item slider">
                    <div class="carousel-container">
                      <Image :fpath="slide.path"  class="clientimg"/>
                       </div>
                  </div>
                </Slide>
                <template #addons>
                <Navigation class="clientnextprev"/>
                  <Pagination />
                </template>
              </Carousel>
          </div>
     </div>
 </div>
    </div>
</template>

<script>

import { defineComponent } from 'vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
import Image from '@/components/Image.vue'
import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Image
  },
  data() {
   return { slider: [ { id: 29, path: 'clients/a29.png', },{ id: 30, path: 'clients/a30.png', },{ id: 31, path: 'clients/a31.png', },{ id: 3, path: 'clients/a3.png', }, { id: 4, path: 'clients/a4.png', },{ id: 5, path: 'clients/a5.png', },{ id: 6, path: 'clients/a6.png', },{ id: 7, path: 'clients/a7.jpg', },{ id: 8, path: 'clients/a8.png', },{ id: 9, path: 'clients/a9.png', },{ id: 10, path: 'clients/a10.png', },{ id: 11, path: 'clients/a11.png', },{ id: 12, path: 'clients/a12.png', },{ id: 13, path: 'clients/a13.png', },{ id: 14, path: 'clients/a14.png', },{ id: 15, path: 'clients/a15.png', },{ id: 16, path: 'clients/a16.png', },{ id: 17, path: 'clients/a17.png', },{ id: 18, path: 'clients/a18.png', },{ id: 19, path: 'clients/a19.png', },{ id: 20, path: 'clients/a20.png', },{ id: 21, path: 'clients/a21.png', },{ id: 22, path: 'clients/a22.png', },{ id: 23, path: 'clients/a23.png', },{ id: 28, path: 'clients/a28.png', },{ id: 24, path: 'clients/a24.png', },{ id:25, path: 'clients/a25.png', },{ id: 26, path: 'clients/a26.png', },{ id: 27, path: 'clients/a27.png', },{ id: 1, path: 'clients/a1.png', }, { id: 2, path: 'clients/a2.jpg', }] }
   },
   settings: {
      snapAlign: 'center',
    },
});
</script>
<style>
  .carousel__prev.clientnextprev{
      box-sizing: content-box;
      top: 30%;
      background-color: #72287a;
      left: 0px;
      border: 3px solid;
      transform: translate(50%, -50%);
  }
  .carousel__next.clientnextprev {
      box-sizing: content-box;
      background-color: #72287a;
      top: 30%;
      right: 30px !important;
      border: 3px solid;
      transform: translate(50%, -50%);
  }
  
  @media (max-width: 767px) {
        .carousel__prev.clientnextprev {
            top: 35%;
            left: 0px;
            opacity: 40%;
        }
        .carousel__next.clientnextprev {
            top: 35%;
            right: 20px;
            opacity: 40%;
        }
        .carousel__prev.clientnextprev:hover {
            opacity: 100%;
        }
        .carousel__next.clientnextprev:hover {
            opacity: 100%;
        }
        }
        .carousel__prev.clientnextprev:hover{
                background-color:#ed1d8d;
         }
         .carousel__next.clientnextprev:hover{
                background-color:#ed1d8d;
            }

@media only screen and (min-width: 991px) and (max-width: 2561px) {
.ClientCarousel.mobileclient{
    display:none;
  }

}
@media only screen and (min-width: 0px) and (max-width: 990px) {
.ClientCarousel.desktop{
    display:none;
  }
}
.carousel__pagination-button--active {
    background-color: #72287a;
}
.carousel__pagination-button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}
  .carousel__item {
      min-height: 100px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .slider{
      border:1px solid #666;
  }
  .carousel-container> .clientimg{
    width:70%;
  }
  li.clientslide{
      padding:10px;
  }

  .ClientCarousel {
      width: 100%;
      padding:0px 0px 40px 0px;
  }
  .maincontent{
      padding-top: 10px;
  }
  @media (max-width: 767px) {
   ol.carousel__pagination {
    display: none;
    }
    .carousel-container> .clientimg {
        width: 100%;
    }
    }
 

</style>
