<template>
    <!-- ======= Hero Section ======= -->
  <section id="hero" class="desktop">
    <div class="container">
      <div class="row d-flex align-items-center">
      <div class=" col-lg-6 py-5 py-lg-0 animated fadeInRight">
        <h1>Markuci delivers digital transformation and technology services</h1>
        <h2>We work with people and organizations who have ambitious missions</h2>
      </div>
      <div class="col-lg-6 hero-img" data-aos="fade-right">
        <img src="../assets/images/hero-img.png" class="img-fluid" alt="">
      </div>
    </div>
    </div>
  </section><!-- End Hero -->
  <section id="hero" class="mobile">
    <div class="container">
      <div class="row d-flex align-items-center">
      <div class=" col-lg-12 py-5 py-lg-0 order-2 order-lg-1" data-aos="fade-right">
        <h1>Markuci delivers digital transformation and technology services</h1>
        <h2>We work with people and organizations who have ambitious missions</h2>
      </div>
    </div>
    </div>
  </section><!-- End Hero -->
</template>
<style>
#hero {
  width: 100%;
  padding: 120px 0 0 0;
  background: #213b52;
  background: linear-gradient(180deg, #213b52 0%, #213c54 35%, #204564 100%);
}
#hero h1 {
  margin: 0 0 15px 0;
  font-size: 45px;
  font-weight: 700;
  line-height: 70px;
  color: #d2e0ed;
  font-family: "Poppins", sans-serif;
}
#hero h2 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 40px;
}
.mobile {
    display: none;
}
@media (max-width: 991px) and (min-width: 0px)  {
    .desktop {
    display: none;
}
.mobile {
    display: block;
    background: url(../assets/images/hero-img.png) center center no-repeat !important;
    background-size: cover;
    padding: 100px 20px 100px 20px;
    position: relative;
  z-index: 1;
}
.mobile::before {
  content: "";
  position: absolute;
  background: rgba(1, 9, 32, 0.849);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
}
@media (max-width: 991px) {
  #hero .hero-img img {
    display: none;
  }

}
@media (max-width: 768px) {
  
  #hero {
    -moz-text-align-last: center;
    text-align-last: center;
  }
  section#hero {
    padding: 150px 50px 30px 50px;
}
  #hero h1 {
    font-size: 35px;
    text-align: center;
    line-height: 50px;
  font-weight: 700;
  color: #d2e0ed;
  font-family: "Poppins", sans-serif;
  }
  #hero h2 {
    font-size: 25px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 30px;
  }
  #hero .hero-img img {
    display: none;
  }
}
@media (max-width: 575px) and (min-width: 351px) {
  #hero .hero-img img {
    width: 80%;
  }
  section#hero {
    padding: 150px 50px 30px 50px;
}
 #hero h1 {
    font-size: 40px;
    text-align: center;
    line-height: 60px;
  font-weight: 700;
  color: #d2e0ed;
  font-family: "Poppins", sans-serif;
  }
  #hero h2 {
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 30px;
  }
}
@media (max-width: 350px) and (min-width: 0px) {
   section#hero {
    padding: 120px 50px 30px 50px;
}
 #hero h1 {
    font-size: 25px;
    line-height: 40px;
  }
  #hero h2 {
    font-size: 16px;
    line-height: 30px;
  }
}
</style>