<template>
    <Header/>
    <Hero/>
    <Client/>
    <Solutions/>
    <Service/>
    <About/>
    <Skill/>
    <!-- <Careers/> -->
    <!-- <Clients/> -->
    <Faq/>
    <!-- <Progress/> -->
    <Contact/>
    <Footer/>
    
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import About from '@/components/About.vue'
import Skill from '@/components/Skill.vue'
import Service from '@/components/Service.vue'
import Faq from '@/components/Faq.vue'
import Solutions from '@/components/Solutions.vue'
// import Progress from '@/components/Progress.vue'
import Contact from '@/components/Contact.vue'
import Footer from '@/components/Footer.vue'
import Hero from '@/components/Hero.vue'
// import Clients from '@/components/Clients.vue'
import Client from '@/components/clientslider.vue'
//  import Careers from '@/components/Careers.vue'


export default {
  components: {
    Header,
    About,
    Skill,
    Service,
    Faq,
    Solutions,
    // Progress,
    Contact,
    Client,
    Footer,
    Hero,
    // Clients,
  //  Careers
  }
}
</script>
