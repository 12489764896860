<template>
    <section id="counts" class="counts">
      <div class="container">

        <div class="text-center title">
          <h3>What we have achieved so far</h3>
          
        </div>

        <div class="row counters position-relative">

          <div class="col-lg-3 col-6 text-center">
            <img src="../assets/like.png" class="Client">
            <vue3-autocounter ref='counter' :startAmount='0' :endAmount='2006' :duration='3' :autoinit='true'/>
             <p>Satisfied Client</p>
          </div>

          <div class="col-lg-3 col-6 text-center">
            <img src="../assets/chat.png" class="Client">
            <vue3-autocounter ref='counter' :startAmount='0' :endAmount='240' :duration='3' :autoinit='true'/>
            <p>Active Customers</p>
          </div>

          <div class="col-lg-3 col-6 text-center">
            <img src="../assets/idea.png" class="Client">
            <vue3-autocounter ref='counter' :startAmount='0' :endAmount='782' :duration='3' :autoinit='true'/>
            <p>Projects Delivered</p>
          </div>

          <div class="col-lg-3 col-6 text-center">
            <img src="../assets/trophy.png" class="Client">
            <vue3-autocounter ref='counter' :startAmount='0' :endAmount='10' :duration='3' :autoinit='true'/>
            <p>Awards Won</p>
          </div>

        </div>

      </div>

       


    </section>
</template>

<script>
import { defineComponent } from 'vue';
import Vue3autocounter from 'vue3-autocounter';

export default defineComponent({
  components: {
    'vue3-autocounter': Vue3autocounter
  }
}); 
</script>

<style>
.counts {
    background: url(../assets/business-partners.jpg) center center no-repeat;
    background-size: cover;
    padding: 80px 0 60px 0;
    position: relative;
}
img.Client {
    width: 35%;
}
.counts {
  padding: 80px 30px 60px 30px;
  position: relative;
}
.counts::before {
  content: "";
  position: absolute;
  background: rgba(245, 74, 154, 0.411);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.counts .title {
  position: relative;
  color: #fff;
  margin-bottom: 40px;
}
.counts .title h3 {
  font-size: 36px;
  font-weight: 700;
}
.counts .counters span {
  font-size: 45px;
  display: block;
  font-weight: 700;
  color: #FFCC33;
}
.counts .counters p {
  padding: 0;
  margin: 0 0 20px 0;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 800;
}
@media (min-width: 1200px) {
  .counts {
    background-attachment: fixed;
  }
}
@media (max-width: 451px) {
  .counts .counters p {
  font-size: 14px;
}
.counts .counters span {
  font-size: 35px;
}
}
</style>